export const skills = [
  {
    id: "abc",
    title: "Película automotiva"
  },
  {
    id: "cde",
    title: "Som automotivo"
  },
  {
    id: "def",
    title: "Alarme automotivo"
  },
  {
    id: "ghi",
    title: "Estética automotiva"
  }
]
