export const people = [
  {
    id: "abc",
    name: "Gustavo Rocha",
    city: "São Paulo",
    state: "SP",
    photoUrl: "/images/avatar/bigode.jpg",
    bio: "Um bigode sensciente",
    skills: [
      {
        id: "abc",
        title: "Película automotiva",
        rating: 2,
        since: '2019-10-12'
      },
      {
        title: "Som automotivo",
        rating: 4,
        since: '2017-12-19'
      },
    ]
  },
  {
    id: "cde",
    name: "Ricardo Nobuo",
    city: "Niterói",
    state: "RJ",
    photoUrl: "/images/avatar/nobuo.jpg",
    bio: "Biografia do usuário, potencialmente cobre múltiplas linhas, como deveria ser o caso desse exemplo aqui. Eventualmente o usuário poderá editar este campo, talvez faça sentido limitar o número de caracteres ou algo do gênero, ou no mínimo separar o nó do restante da árvore para não carregar tudo isso quando puxar os usuários para a(s) tabela(s)",
    skills: [
      {
        title: "Película automotiva",
        rating: 3,
        since: '2014-02-08'
      },
      {
        title: "Som automotivo",
        rating: 1,
        since: '2019-06-22'
      },
      {
        title: "Alarme automotivo",
        rating: 4,
        since: '2018-07-02'
      },
    ]
  }
]