import React from 'react';
import dayjs from 'dayjs';

import Rating from 'material-ui-rating';

import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import { Star } from '@material-ui/icons';

import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';

import PropTypes from 'prop-types';

// TODO: replace external library with material ui lab component

const defaultIcon = (<Star htmlColor={ amber[500] }/>);
const staleIcon = (<Star htmlColor={ red[100] } />); 
//const staleIcon = (<Star htmlColor="#B7272E" />); // Color from the website

const isStale = (since) => 
  (dayjs().diff(dayjs(since.seconds * 1000), 'year') >= 1)


const label = (value) => 
{
  switch(value) {
    case 1:
      return "Básico";
    case 2:
      return "Intermediário";
    case 3:
      return "Avançado";
    case 4: 
      return "Especialista";
    case 5:
      return "Especialista";
    default:
      return "N/A"
  }
}

export const SkillRatings = (props) => 
  <React.Fragment>
    {
      props.skills.map(skill => (
        <React.Fragment key={skill.title}>
          <Grid container alignItems="center">
            <Grid item>
              <Typography  variant="body1">{skill.title}:</Typography>
            </Grid>
            <Grid item>
            {skill.since ? 
             <Rating  value={skill.rating} max={(Math.max(4, skill.rating))} iconFilled={isStale(skill.since) ? staleIcon : defaultIcon} readOnly/> :
             <Rating  value={skill.rating} max={(Math.max(4, skill.rating))} readOnly/>
            }
            </Grid>
            <Grid item>
              <Typography  variant="body1"> ({label(skill.rating)}{isStale(skill.since) ? " - Vencido" : ""})</Typography>
            </Grid>

          </Grid>
         
        </React.Fragment>
      ))
    }
  </React.Fragment>

SkillRatings.propTypes = {
  skills: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    since: PropTypes.shape({
      seconds: PropTypes.number.isRequired
    }).isRequired
  }))
}
