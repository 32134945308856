import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCElZFkbwfLT4hMhiR1QxAYnHdcSScSGOE",
  authDomain: "perfil-profissional-segvel.firebaseapp.com",
  databaseURL: "https://perfil-profissional-segvel.firebaseio.com",
  projectId: "perfil-profissional-segvel",
  storageBucket: "perfil-profissional-segvel.appspot.com",
  messagingSenderId: "961465775461",
  appId: "1:961465775461:web:1eeae0357c23026d"
});

const db = firebaseApp.firestore();

const storage = firebaseApp.storage();


const mapDoc = doc => {

  const obj = doc.data()
  return  Object.keys(obj).reduce(
    (p, c) => 
      {
        p.push({...obj[c], "id": c});
        return p
      },
      []
    )
}

export { db, storage, mapDoc };