import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';

import MaterialTable from 'material-table';

import { getCourses } from '../../../services/course';

const courses = getCourses().reduce(
  (previous, current, index) => {
    previous[current.id] = current.title
    return previous
  },
  {}
)


class AddCourseTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { 
          title: 'Treinamento',
          field: 'id',
          lookup: courses
         },
        { title: 'Quando', 
        field: 'when',
        // TODO: use date picker,store on state
        editComponent: props => (
          <input
              type="text"
              value={props.value}
              onChange={e => props.onChange(e.target.value)}
            />
          )
       },
      ],
      data: []
    }
  }

  render() {
    return (
      <MaterialTable
        title="Treinamentos"
        components = {{
          Container: props => (<Paper elevation={0}>{props.children}</Paper>)
        }}
        columns={this.state.columns}
        data={this.state.data}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                {
                  const data = this.state.data;
                  data.push(newData);
                  this.setState({ data }, () => resolve());
                }
                resolve()
              }, 1000)
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                {
                  const data = this.state.data;
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  this.setState({ data }, () => resolve());
                }
                resolve()
              }, 1000)
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                {
                  let data = this.state.data;
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  this.setState({ data }, () => resolve());
                }
                resolve()
              }, 1000)
            }),
        }}
        options= {{
          search: false
        }}
        localization={{
          pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'linhas'
          },
          header: {
              actions: 'Ações'
          },
          body: {
              emptyDataSourceMessage: 'Nenhum registro a ser exibido',
              editRow: {
                saveTooltip: 'Salvar',
                cancelTooltip: 'Cancelar',
                deleteText: 'Você tem certeza que quer remover esta linha?'
              }
          }
      }}
      />
    )
  }
}

export default AddCourseTable