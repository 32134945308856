import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import MaskedInput from 'react-text-mask';


import { getStates } from '../../services/state';

import AddCourseTable from './add-course-table';

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  }
})



function PhoneInput(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /[1-9]/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}


 class ProfileForm extends Component {

  // TODO: 
  // mask phone, validate email
  // call dummy function on save
  // non-zeroed version (if param on url?)
  

  constructor(props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      phone: '',
      state: '',
      city: '',
      courses: []
    }

    this.handleRating = this.handleRating.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }


  handleRating = name => value => {
    this.setState({ ...this.state, [name]: { ...this.state[name], rating: value } });
  };

  handleSwitch = name => event => {
    this.setState({ ...this.state, [name]: { ...this.state[name], stale: event.target.checked } });
  };

  handleSelect = event => 
    this.setState({ ...this.state, state: event.target.value });

  render() {

    const { classes, open, handleClose } = this.props;
    const { state } = this.state;

    return (

      <Dialog open={open} onClose={handleClose} fullScreen>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Adicionar perfil
            </Typography>
            <Button color="inherit" onClick={handleClose}>
              salvar
            </Button>
          </Toolbar>
        </AppBar>
        
        <Grid container item lg={4} className={classes.dialogContent}>
          <TextField
            required          
            margin="dense"
            id="name"
            label="Nome"
            fullWidth
          />
          <TextField
            required
            margin="dense"
            id="email"
            label="Endereço de email"
            type="email"
            fullWidth
          />
          <TextField
            required
            margin="dense"
            id="phone"
            label="Telefone"
            fullWidth
            InputProps={{
              inputComponent: PhoneInput,
            }}
          />
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="state-select">Estado</InputLabel>
            <Select
              value={state}
              onChange={this.handleSelect}
              inputProps={{
                name: 'state',
                id: 'state-select',
              }}
            >
              { getStates().map(state => <MenuItem key={state} value={state}>{state}</MenuItem>)}
            </Select>
          </FormControl>

          <TextField
            required          
            margin="dense"
            id="city"
            label="Cidade"
            fullWidth
          />

          <TextField
            id="bio"
            label="Biografia"
            multiline
            rows="4"
            //className={classes.textField}
            margin="normal"
            fullWidth
          />

        </Grid>

        <Grid container  alignItems="center" className={classes.dialogContent}>
          <Grid item>
          

            <Button
              variant="contained"
              component="label"
            >
              Foto
              <CloudUploadIcon className={classes.rightIcon}/>
              <input
                type="file"
                style={{ display: "none" }}
              />
            </Button>
          </Grid>

          
        </Grid>

        <Grid container >
          <Grid item lg={12} className={classes.dialogContent}>
            <AddCourseTable />
          </Grid>          
        </Grid>

        
        
      </Dialog>

    )
  }

}

export default withStyles(styles)(ProfileForm)