import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';


import CertificateForm from './certificate-form';


import { getSkills } from '../../services/skill';

import Rating from 'material-ui-rating';

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  }
})


const skills = getSkills();

// TODO: Skills são select antes do rating (rating para única skill)



const SkillRow = (props) => (
  <Grid container alignItems="center" className={props.classes.dialogContent}>
    <Grid item>
      <Typography variant="body1" >{props.title}:</Typography>
    </Grid>
    <Grid sm={9} lg={9}  item>
      <Rating  value={props.rating} max={4} onChange={props.handleRating} />
    </Grid>    
  </Grid>
)

 class CourseForm extends Component {
  

  constructor(props) {
    super(props);

    this.state= {
      title: '',
      certificateEnabled: false,
      skill: skills[0].id,
      rating: 0
    }

    this.handleRating = this.handleRating.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }


  handleRating = value => {
    this.setState({ ...this.state, rating: value });
  };

  handleSwitch = event => {
    this.setState({ certificateEnabled: event.target.checked });
  };

  handleSelect = event => {
    this.setState({ 
      ...this.state, 
      skill: event.target.value
    });
  };


  render() {

    const { classes, open, handleClose } = this.props;
    const { certificateEnabled, skill, rating  } = this.state;

    return (

      <Dialog open={open} onClose={handleClose} fullScreen>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Criar treinamento
            </Typography>
            <Button color="inherit" onClick={handleClose}>
              salvar
            </Button>
          </Toolbar>
        </AppBar>
        
        <Grid container item lg={4} className={classes.dialogContent}>
          <TextField
            required          
            margin="dense"
            id="title"
            label="Título do treinamento"
            fullWidth
          />

        </Grid>


        <Grid container alignItems="center" className={classes.dialogContent}>
          <Grid item>
            <FormControl >
              <InputLabel htmlFor="skill">Competência</InputLabel>
              <Select
                value={skill}
                onChange={this.handleSelect}
                inputProps={{
                  name: 'skill',
                  id: 'skill',
                }}
              >
                {skills.map(skill => 
                  <MenuItem key={skill.id} value={skill.id}>{skill.title}</MenuItem>
                )
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Rating  value={rating} max={4} onChange={this.handleRating} />
          </Grid>
        </Grid>
        
        

        <Typography variant="h6" className={classes.dialogContent}>Certificação:</Typography>

        <Grid container item lg={4} className={classes.dialogContent}>
          <FormControlLabel
            control={<Switch checked={certificateEnabled} value="certificate" onChange={this.handleSwitch}  />}
            label="Certifica o participante?"
          />
        </Grid>
        <CertificateForm disabled={!certificateEnabled}/>

      </Dialog>

    )
  }

}

export default withStyles(styles)(CourseForm)