import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';


import SchoolIcon from '@material-ui/icons/School';
import { withStyles } from '@material-ui/core';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(LocalizedFormat)



const styles = theme => ({
    bar: {
      '&::before': {
        background: theme.palette.primary.accent 
      }
    },
    // Replicates material-ui Paper implementation
    paper: {
        '& div div': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        transition: theme.transitions.create('box-shadow'),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
        
        // Not on paper
        borderTop: `solid 3px ${theme.palette.secondary.main}`
      },
      '& div div::before': {
        borderRightColor: theme.palette.primary.accent 
      }
    }
  }
)

class CourseTimeline extends Component {

  
  render() {

    const { courses, classes, theme } = this.props;
    
    const iconStyle = {
      color: theme.palette.primary.contrastText,
      background: theme.palette.secondary.main,
    }

    return (<div>
      <VerticalTimeline
        //layout="1-column"
        animate={false}
        className={classes.bar}
      >
        {courses.map(course => (
          <VerticalTimelineElement 
            key={course.title}
            date={dayjs(course.when.seconds * 1000).locale('pt-br').format('L')}
            icon={<SchoolIcon />}
            iconStyle={iconStyle}
            className={classes.paper}
          >
            <Typography variant="subtitle1" color="textSecondary" >{course.title}</Typography>
          </VerticalTimelineElement>)
          
        )}
      </VerticalTimeline>
    </div>)
  }
} 

export default withStyles(styles, { withTheme: true })(CourseTimeline)