import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  certificate: {
    height: 32
  },
  cell: {
    textAlign: "center",
  }
})


class CertificateGrid extends Component {


  render() {
    const {certificates, classes} = this.props;

    return (
      <Grid container alignItems="center">
        {certificates && certificates.map(certificate => (
          <Grid key={certificate.id} xs={3} item className={classes.cell}>
            <img               
              src={certificate.image} 
              alt={certificate.title} 
              title={certificate.title}
              className={classes.certificate}
            />
          </Grid>
        ))}
      </Grid>
    )
  }
}

export default withStyles(styles)(CertificateGrid)