import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Typography from '@material-ui/core/Typography';

import Hidden from '@material-ui/core/Hidden';

import QRCode from 'qrcode-react';

import { getPerson, getPersonAvatar } from '../../services/people';
import { getPersonCertificates } from '../../services/certificate';
import { getPersonCourses } from '../../services/course';

import { SkillRatings } from './ratings';
import CourseTimeline from './course-timeline';
import CertificateGrid from './certificate-grid';

import PropTypes from 'prop-types';


const styles = theme => ({
  
  content: {
    padding: theme.spacing(2),
  },
  logo: {
    maxWidth: "45%"
  },
  header: {
    background: theme.palette.primary.main,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  qr: {
    textAlign: "center",
    [theme.breakpoints.up('md')] : {
      order: 1
    }
  },
  badges: {
    [theme.breakpoints.up('md')] : {
      textAlign: "center",
      
    },
    [theme.breakpoints.up('sm')] : {
      order: 2,
    }
  },
  bio: {
    [theme.breakpoints.up('sm')] : {
      order: 4
    }
  },
  timeline: {
    [theme.breakpoints.up('sm')] : {
      order: 3,
      textAlign: "center",
    }
  },
  paper: {    
    margin: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  padBottom: {
    paddingBottom: theme.spacing(2),
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  avatar: {
    width: "70%",
    margin: "auto",
    textAlign: "center"
  }
});


class PersonProfile extends Component {

  state = { person: null, certificates: null, courses: null }

  async componentDidMount () {
    const { id } = this.props.match.params

    const person = await getPerson(id);
    const certificates = await getPersonCertificates(id);
    const courses = await getPersonCourses(id);
    const avatar = await getPersonAvatar(person.avatar);
    

    this.setState({ 
      person: {...person, photoUrl: avatar },
      certificates: certificates,
      courses: courses
    })
  }

  render() {

    const { classes } = this.props;

    const { person, certificates, courses } = this.state

    return(

      <Paper className={classes.paper}>
        { person && <Grid container>

           <Grid xs={12} md={12} lg={12} item >
             <Paper elevation={0} className={`${classes.header} ${classes.content}`}>
               <Grid container justify="space-between" alignItems="center">
                 <Grid item xs={6}>
                   <img alt="Logo segvel" src="/images/logos/segvel.png" className={classes.logo}/>
                  </Grid>
                 <Grid item xs={6} className={classes.right}>
                   <img alt="Logo 3M" src="/images/logos/3m.png" className={classes.logo}/>
                  </Grid>
               </Grid>
             
             </Paper>
              
            </Grid>
            <Grid xs={12} sm={6} md={3} item className={`${classes.content} ${classes.center}`}>
              <img alt="avatar" src={person.photoUrl} className={classes.avatar} />
            </Grid>
            
            <Grid xs={12} sm={6} md={3} item  className={`${classes.qr} ${classes.content}`}>
              <QRCode value={window.location.href}/>
            </Grid>


            <Grid xs={12} md={6} item className={classes.content} >
              <Typography component="h5" variant="h5">
                {person.name}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" className={classes.padBottom}>
                {person.city} - {person.state}
              </Typography>
              <Hidden mdUp>
                <Typography variant="subtitle1" color="textSecondary">Competências</Typography>
              </Hidden>
              {person.skills && <SkillRatings skills={person.skills}  /> }
            </Grid>

            <Grid xs={12} md={3} item  className={`${classes.badges} ${classes.content}`}>
              <Hidden mdUp>
                <Typography variant="subtitle1" color="textSecondary">Certificações</Typography>
              </Hidden>
              { certificates && <CertificateGrid certificates={certificates} />}
            </Grid>
            
            <Grid xs={12} md={6} item  className={`${classes.timeline} ${classes.content}`}>
              <Hidden mdUp>
                <Typography variant="subtitle1" color="textSecondary">Histórico</Typography>
              </Hidden>
              { courses && <CourseTimeline courses={courses} />}
            </Grid>
            <Grid xs={12} md={3} item  className={`${classes.bio} ${classes.content}`}>
            <Hidden mdUp>
                <Typography variant="subtitle1" color="textSecondary">Biografia</Typography>
              </Hidden>
              <Typography variant="body1" color="textSecondary">{person.bio}</Typography>
            </Grid>
        </Grid>
        }
      </Paper>

    )
  }
}

PersonProfile.propTypes = {
  person: PropTypes.shape({
    name: PropTypes.string.isRequired,
    city: PropTypes.string,
    state: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      rating: PropTypes.number.isRequired,
      since: PropTypes.string.isRequired
    }))
  })
}

export default withStyles(styles)(PersonProfile)