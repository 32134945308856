import React, { Component } from 'react';


import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DoneIcon from '@material-ui/icons/Done';

import MaterialTable from 'material-table';

import { getCourses } from '../../services/course';

import Rating from 'material-ui-rating';

import CourseForm from '../course-form'


// TODO: filter on Skill not working

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20

  },
})

const courses = getCourses();




export class CourseTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false
    }

    this.openDialog = this.openDialog.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
  }


  
  openDialog() {
    this.setState({ dialogOpen: true })
  }

  closeDialog() {
    this.setState({ dialogOpen: false })
  }

  render() {

    const { classes } = this.props;
    const { dialogOpen } = this.state;

    return (
      <Grid container className={classes.root}>
        <Grid xs={12} md={12} lg={12}  item>
          <MaterialTable
            title="Treinamentos"
            columns={[              
              { 
                title: 'Título', 
                field: 'title'
              },
              {
                title: "Competência",
                field: "skill.rating",
                render: rowData => <Rating value={rowData.skill.rating} max={4} readOnly />
              },
              { 
                title: 'Certifica?',
                field: 'certificate',
                filtering: false,
                render: rowData => rowData.certificate ? <DoneIcon /> : <div></div>
              }
            ]}
            data={courses}
            options={{
              filtering: true,
              search: true
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Criar treinamento',
                isFreeAction: true,
                onClick: (event) => this.openDialog()
              }
            ]}
          />
          <CourseForm open={dialogOpen} handleClose={this.closeDialog} />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(CourseTable)