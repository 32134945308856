import { db, storage, mapDoc } from './firebase'


export const getPersonCertificates = async (id) =>
db.collection('peopleCertificates').doc(id).get().then(doc => {
  if(doc.exists) {
    const certificates = mapDoc(doc)
    return Promise.all(certificates.map(appendCertificateImage))
  }
  else {
    return Promise.resolve(null)
  }
})



const appendCertificateImage = certificate => 
  getCertificateImage(certificate.id).then(url => 
    Promise.resolve({...certificate, image: url})
  )

const getCertificateImage = id =>
  storage.ref().child(`certificates/${id}.png`).getDownloadURL()