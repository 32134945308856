import React, { Component } from 'react';

import { Link } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import MaterialTable from 'material-table';

import { people } from '../../data/people';
import { getStates } from '../../services/state';
import { getSkills } from '../../services/skill';

import ProfileForm from '../profile-form';



// TODO: filter on Skill not working

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20

  },
})

const buildLookup = entries => 
  entries.reduce((result, item) => {
    result[item] = item; 
    return result;
  }, {})


  const bestSkill = skills => 
  skills.reduce((prev, current) =>
     prev +  current.title + ": " + current.rating + " ", ""
  )
  

  const bestSkill2 = skills => 
    skills.reduce((prev, current) =>
      (prev.rating > current.rating) ? prev : current
    ).title
    


export class PeopleTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false
    }

    this.openDialog = this.openDialog.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
  }


  
  openDialog() {
    this.setState({ dialogOpen: true })
  }

  closeDialog() {
    this.setState({ dialogOpen: false })
  }

  render() {

    const { classes } = this.props;
    const { dialogOpen } = this.state;

    return (
      <Grid container className={classes.root}>
        <Grid xs={12} md={12} lg={12}  item>
          <MaterialTable
            title="Perfis"
            columns={[
              {
                title: '',
                field: 'photoUrl',
                filtering: false,
                render: rowData => rowData.photoUrl ? 
                  <Avatar alt={rowData.name} src={rowData.photoUrl} /> : 
                  <Avatar alt={rowData.name} >{rowData.name.charAt(0).toUpperCase()}</Avatar>
              },              
              { 
                title: 'Nome', 
                field: 'name',
                render: rowData => <Link to={"/profile/" + rowData.id} >{rowData.name}</Link>
              },
              {
                title: "Maior Competência",
                field: "skills",
                //lookup: buildLookup(getSkills()),
                render: rowData => <React.Fragment>{bestSkill(rowData.skills)}</React.Fragment>
              },
              { title: 'Cidade', field: 'city'},
              { title: 'Estado', field: 'state', lookup: buildLookup(getStates())}
            ]}
            data={people}
            options={{
              filtering: true,
              search: true
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Adicionar perfil',
                isFreeAction: true,
                onClick: (event) => this.openDialog()
              }
            ]}
          />
          <ProfileForm open={dialogOpen} handleClose={this.closeDialog} />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(PeopleTable)