import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


const styles = theme => ({

  dialogContent: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  }
})


class CertificateForm extends Component {  


  render() {

    const { classes, disabled } = this.props;

    return (
      <React.Fragment>
        
        <Grid container item lg={4} className={classes.dialogContent}>
          <TextField
            required          
            margin="dense"
            id="title"
            label="Título do certificado"
            fullWidth
            disabled={disabled}
          />
          <TextField
            id="description"
            label="Descrição do certificado"
            margin="dense"
            rowsMax="4"
            fullWidth
            disabled={disabled}
          />
          

        </Grid>

        <Grid container  alignItems="center" className={classes.dialogContent}>
          <Grid item>
          

            <Button
              variant="contained"
              component="label"
              disabled={disabled}
            >
              Imagem
              <CloudUploadIcon className={classes.rightIcon}/>
              <input
                type="file"
                style={{ display: "none" }}
              />
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>

    )
  }

}

export default withStyles(styles)(CertificateForm)