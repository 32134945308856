
import { courses } from '../data/courses';

import { db, mapDoc } from './firebase';


export const getCourses = () => courses;

export const getCourse = (id) =>
  courses.find(course => course.id === id);

export const getPersonCourses = (id) =>
  db.collection('peopleCourses').doc(id).get().then(doc => {
    if(doc.exists) {
      return Promise.resolve(mapDoc(doc))        
    }
    else {
      return Promise.resolve(null)
    }
  })
