export const courses = [
  {
    id: "abc",
    title: "Película automotiva - Módulo básico",
    certificates: null,
    skill: {
      id: "abc",
      title: "Película automotiva",
      rating: 1
    }
  },
  {
    id: "cde",
    title: "Película automotiva - Módulo intermediário",
    certificates: null,
    skill: {
      id: "abc",
      title: "Película automotiva",
      rating: 2
    }
  },
  {
    id: "fgh",
    title: "Película automotiva - Módulo avançado",
    skill: {
      id: "abc",
      title: "Película automotiva",
      rating: 3
    }
  },
  {
    id: "ijk",
    title: "Técnico em Película automotiva",
    certificate: {
      title: "Aplicador de película automotiva certificado",
      description: "Certificação em algo potencialmente relevante, ao menos o suficiente para ganhar um carimbo.",
      image: "/images/certificates/3m.png"
    },
    skill: {
      id: "abc",
      title: "Película automotiva",
      rating: 4
    }
  },
  {
    id: "opr",
    title: "Reciclagem para Aplicadores de película automotiva",
    certificate: {
      title: "Aplicador de película automotiva certificado",
      description: "Certificação em algo potencialmente relevante, ao menos o suficiente para ganhar um carimbo.",
      image: "/images/certificates/3m.png"
    },
    skill: {
      id: "abc",
      title: "Película automotiva",
      rating: 4
    }
  },
  
]

// TODO: add extra trainings