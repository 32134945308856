import React from 'react';

import { BrowserRouter as Router, Route } from "react-router-dom";

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';

import './App.css';

import PersonProfile from './components/person-profile';
import PeopleTable from './components/people-table';
import CourseTable from './components/course-table';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#001120", // from the website
      accent: grey[200]
    },
    secondary: {
      main: "#B7272E", // from the website
      accent: red[200],
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
        </div>

        <Route path="/profile/:id" component={PersonProfile} />
    
      </Router>
    </ThemeProvider>
    
  );
}

export default App;
