import { db, storage} from './firebase';


export const getPerson = (id) => 
  db.collection('people').doc(id).get().then(doc => {
    if(doc.exists) {
      return Promise.resolve(doc.data())
    }
    else {
      Promise.reject("Nenhum documento com o id especicado")
    }
  })

export const getPersonAvatar = (filename) =>
  storage.ref().child(`avatar/${filename}`).getDownloadURL()